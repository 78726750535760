:root {
  --bg-color: #f7f4ed;
  --text-color: #6b7c93;
  --link-color: #111F2C;
  --title-color: #111F2C;
  --subtitle-color: #111F2C;
}

* {
  box-sizing: border-box;
}

html.site, body.site {
  background: var(--bg-color);
  color: var(--text-color);
  font-family: Fira Code,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
  line-height: 140%;
}

.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

@media all and (max-width: 800px) {
  .container {
    padding: 15px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin-top: 80px;
  margin-bottom: 70px;
}

.dot {
  background-color: #f9a100;
  background-image: linear-gradient(-225deg,#f9a100,#ffd149);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  line-height: 140%;
}

.logo {
    margin-bottom: 20px;
}

.header__title {
  font-size: 56px;
  font-weight: 700;
  color: var(--title-color);
  line-height: 140%;
  text-align: center;
  margin-bottom: 40px;
}

@media all and (max-width: 600px) {
  .header__title {
    font-size: 32px;
  }
}

.header__lead {
  font-size: 21px;
  text-align: center;
  line-height: 150%;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.features__feature {
  font-size: 18px;
  line-height: 150%;
  font-weight: 600;
  padding: 20px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 1fr;
  align-items: center;
}

.features__feature.features__feature--1 {
  background: #fde2e2;
  color: #e67c7c;
}

.features__feature.features__feature--2 {
  background: #aacfcf;
  color: #508282;
}

.features__feature.features__feature--3 {
  background: #e1ccec;
  color: #886aaa;
}

.features__feature.features__feature--4 {
  background: #fcf7bb;
  color: #a27e37;
}

.divider {
  margin: 0 auto;
  margin-bottom: 80px;
  background-image: url('../img/wavy-line-800.svg');
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
}

.signup {
  margin: 0 auto;
  margin-bottom: 150px;
}

.signup-inner {
  max-width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.signup__form {
  display: flex;
  width: 100%;
  justify-content: center;
}

.signup__content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.signup__icon {
  font-size: 46px;
  text-align: center;
  background: #E7DECA;
  color: #827A6A;
  padding: 30px;
  margin: 0 auto;
  margin-bottom: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.signup__title {
  font-size: 28px;
  font-weight: 700;
  line-height: 150%;
  color: var(--subtitle-color);
  margin-bottom: 20px;
  text-align: center;
}

.signup__lead {
  font-size: 22px;
  text-align: center;
  margin-bottom: 50px;
}

.mc-field-group {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.mc-field-group label,
.mc-field-group input {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.mc-field-group label {
  font-size: 14px;
  font-weight: 600;
  color: #111F2C;
}

.mc-field-group input {
  margin-bottom: 20px;
  height: 50px;
  border: 2px solid #DADCDE;
  border-radius: 5px;
  padding: 10px;
  color: var(--text-color);
}

.mc-field-group input:focus {
  border-color: #A3AFBA;
}

.signup__submit {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.signup__btn {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--text-color);
  border-radius: 5px;
  font-size: 18px;
  height: 50px;
  background: #596e79;
  color: #fff;
  font-weight: 700;
  font-family: Fira Code;
}

.signup__btn:hover {
  background: #35434a;
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media all and (max-width: 600px) {
  .footer {
    flex-flow: column;
  }
}

.footer__link {
  color: var(--link-color);
  font-weight: 500;
  text-decoration: none;
  line-height: 160%;
  display: inline-flex;
}

.margin-r {
  margin-right: 10px;
}

.margin-b {
  margin-bottom: 10px;
}

.avatar {
  background: url('../img/avatar.jpg');
  width: 40px;
  height: 40px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-bottom: 15px;
}

.author {
  display: flex;
  flex-flow: column;
  max-width: 300px;
  margin-bottom: 20px;
}

.author__lead {
  margin-bottom: 20px;
}

.author__name {
  color: var(--title-color);
  margin-bottom: 10px;
}

em {
  font-style: italic;
  display: inline;
}

.link {
  color: var(--link-color);
  font-weight: 500;
  text-decoration: none;
  line-height: 160%;
  display: inline-flex;
}

.archive {}

.archive__list {
  list-style-type: disc;
}

.archive__link {
  color: var(--link-color);
  font-weight: 500;
  text-decoration: none;
}
